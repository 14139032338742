import React from "react";
import PageWrapper from "../components/PageWrapper";
import Content from "../sections/temple/templecontent.js";
import Faq from "../sections/temple/templefaq.js";
import Recovery from "../sections/temple/templeglance.js";
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Testimonial from "../sections/index/indexreviews.js";
import Trend from '../components/trends.js';
import Clinic from '../components/NewFind.js'; 
import Saveface from '../components/save.js';
import HeroComponent from "../components/ImageHero.js";
import imgVideo from "../assets/image/jpeg/temple.jpg";
import Glance from "../components/glance.js";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Section } from "../components/Core";


const TempleFillerPage = () => {
  return (
    <>
            <Head title="Temple Filler in London" image="/images/dermamina-home.jpg"  description="Enhance your youthful appearance with our temple filler treatment. Using hyaluronic acid, we smooth wrinkles, restore volume, and contour for a rejuvenated look" keywords="Wrinkle, Hyaluronic, Rejuvenation, anti-ageing, Dermal, Volume, Injection, Aesthetics, Smooth, Contour"/>

            <JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>

      <PageWrapper headerDark>
       
      <HeroComponent
    itext='<span style="color: #1a1a1a;">Temple </span> <span style="color: #00aec7;">Filler</span>'
    secondText="Restore the natural contour and beauty of temples"
    videoAlt="Temple Filler"
    imgVideo={imgVideo} // Specify your image URL here
  />

<Section py={0} className="pt-lg-5">
    <Container >
      <Row>
        <Col lg="12" xs="12" data-aos="fade-up"
              data-aos-duration="500"
              data-aos-once="false"
              data-aos-delay="100">
  <Glance  best="3 - 6 treatments"
  bestalt="best Temple Filler"
  resultstext="9 - 18 months"
  resultsalt="Temple Filler results"
  costdata="£450"
  costalt="Temple Filler Cost"
  timedata="10 - 20 Minutes"
  timealt="Temple Filler Duration Time"
  workdata="Immediately"
  workalt="Temple Filler downtime work"
  paindata="None / Mild"
  painalt="Temple Filler Pain"/>
 </Col>
  </Row>
  </Container>
  </Section>
  <Testimonial />
<Content/>

<Faq />
<Clinic /> 
<Saveface />
<Trend />
      </PageWrapper>
     
    </>
  );
};
export default TempleFillerPage;
